<template>
  <b-card >
    <b-tabs>
      <b-tab :active="currentRouteName=='privacy-policy'" 
      @click="$router.push('/privacy-policy')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/privacy-policy"
            class="no-color"
          >
          <feather-icon icon="HomeIcon" />
          <span>سياسة الخصوصية  </span>
          </a>
        </template>

      </b-tab>
      <b-tab :active="currentRouteName=='quality-policy'"
       @click="$router.push('/quality-policy')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/quality-policy'"
            class="no-color"
          >
          <feather-icon icon="FolderIcon" />
          <span>سياسة الجودة </span>
          </a>
        </template>

        <!-- <CopyProtection></CopyProtection> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='dynamic-pages-list'"
        @click="$router.push('/dynamic-pages-list')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>الصفحات الداينميكية  </span>
        </template>

        <!-- <LinkRedirication></LinkRedirication> -->
      </b-tab>
      <b-tab :active="currentRouteName=='sitemap'"
      @click="$router.push('/sitemap')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/sitemap"
            class="no-color"
          >
          <feather-icon icon="FolderIcon" />
          <span>خريطة الموقع  </span>
          </a>
        </template>

        <!-- <LinkRedirication></LinkRedirication> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='dynamic-sitemap'"
        @click="$router.push('/dynamic-sitemap')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>خريطة موقع دايناميك  </span>
        </template>

        <!-- <LinkRedirication></LinkRedirication> -->
      </b-tab>
      <b-tab :active="currentRouteName=='BecomeInstructor'"
       @click="$router.push('/BecomeInstructor')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/BecomeInstructor"
            class="no-color"
          >
          <feather-icon icon="FolderIcon" />
          <span>كن مدرسًا</span>
          </a>
        </template>

        <!-- <LinkRediractionArabic></LinkRediractionArabic> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='AccerditiosPage'"
        @click="$router.push('/AccerditiosPage')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>الاعتمادات </span>
        </template>

        <!-- <LinkRediractionArabic></LinkRediractionArabic> -->
      </b-tab>
      <b-tab :active="currentRouteName=='footer'"
      @click="$router.push('/footer')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/footer"
            class="no-color"
          >
          <feather-icon icon="FolderIcon" />
          <span> Footer</span>
          </a>
        </template>

        <!-- <CoursesDuration></CoursesDuration> -->
      </b-tab>
      <b-tab :active="currentRouteName=='about_us'"
       @click="$router.push('/AboutUs')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/AboutUs"
            class="no-color"
          >
          <feather-icon icon="FolderIcon" />
          <span> عن lpc</span>
          </a>
        </template>

        <!-- <AnalyticCodes></AnalyticCodes> -->
      </b-tab>
      <b-tab
      :active="currentRouteName=='ConsultingServices'"
        @click="$router.push('/ConsultingServices')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/ConsultingServices"
            class="no-color"
          >
          <feather-icon icon="FolderIcon" />
          <span>خدمات استشارية</span>
          </a>
        </template>

        <!-- <ZOHOIntegration></ZOHOIntegration> -->
      </b-tab>
      <b-tab :active="currentRouteName=='terms'"
       @click="$router.push('/Terms')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/terms"
            class="no-color"
          >
          <feather-icon icon="FolderIcon" />
          <span>الأحكام والشروط</span>
          </a>
        </template>

        <!-- <HomePageSections></HomePageSections> -->
      </b-tab>

    </b-tabs>

    <router-view v-slot="{ Component, route }">
      <indexUser v-if="route.name === 'setting-index' || route.name === 'index-user' " />
      <router-view v-else />
    </router-view>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,

  },
  data() {
    return {

    }
  },
   
  computed: {
    currentRouteName() {
        return this.$route.name;
    }}

}
</script>
